export const smallPop = {
  width: 380,
  height: 240,
};

export const midPop = {
  width: 512,
  height: 480,
};

export const bigPop = {
  width: 680,
  height: 420,
};

export const largePop = {
  width: 720,
  height: 520,
};
